import React, {useEffect} from 'react';
import '../../../css/App.css';
import tiai from '../../assets/img/vids/teensinai.mp4';
import db from '../../assets/img/vids/dbdi.mp4';
import db_ogg from '../../assets/img/vids/dbdi.ogg';


function Videos(){

    useEffect(() => {
        const video = document.getElementById('tiaiVideo');
        if (video) {
            video.currentTime = 28; // Start at 28 seconds
        }
    }, []);


    return(
        <React.Fragment>
            <p className="vidHeader" style={{fontSize: 20}}>and some videos too :)</p>
            <div className="row">

                <div className="col-sm-4" id="videoFrame">
                <iframe width="300" height="200" src="https://player.vimeo.com/video/1065451204#t=32m26s" title="Tech For Finance - Vimeo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen loading="lazy"></iframe>
                <p className = "videoCap" id="videoFrame"> <a href="https://techshecan.org/previous-assemblies/tech-for-finance-secondary">Tech For Finance by Tech She Can in collaboration with Deutsche Bank</a></p>
                </div>

                <div className="col-sm-4" id="videoFrame">
                <video id="tiaiVideo" width="300" height="200" preload="metadata" controls controlsList="nodownload">
                    <source src={tiai} type="video/mp4"></source>
                </video>
                <p className = "videoCap" id="videoFrame"> <a href="https://www.teensinai.com/">Working in an AI team: Teens In 
                AI #AdaHack2021</a></p>
                </div>

                <div className="col-sm-4" id="videoFrame">
                <video width="300" height="200" preload="metadata" controls controlsList="nodownload">
                    <source src={db} type="video/mp4"></source>
                    <source src={db_ogg} type="video/ogg"></source>
                </video>
                <p className = "videoCap" id="videoFrame">
                    <a href="https://www.instagram.com/tv/CRQ3TqejZ-X/?utm_source=ig_web_copy_link">Deutsche Bank D&I - Instagram</a>
                </p>
                </div>

                <div className="col-sm-4" id="videoFrame">
                <iframe width="300" height="200" src="https://durham.cloud.panopto.eu/Panopto/Pages/Viewer.aspx?id=44fcff48-a6ba-4fc8-b54e-ac53009f8805&autoplay=false" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen loading="lazy"></iframe>
                <p className = "videoCap" id="videoFrame">
                    <a href="https://durham.cloud.panopto.eu/Panopto/Pages/Viewer.aspx?id=44fcff48-a6ba-4fc8-b54e-ac53009f8805" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture">Interview with a Software Engineer - Durham University</a>
                </p>
                </div>

                <div className="col-sm-4" id="videoFrame">
                <iframe width="300" height="200" src="https://www.youtube.com/embed/QEFBsGp0esY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen loading="lazy"></iframe>
                <p className = "videoCap" id="videoFrame" style={{textAlign: 'center'}}>
                    <a href="https://www.youtube.com/embed/QEFBsGp0esY">Machine Learning to Combat Cyberbullying</a>
                </p>
                </div>
                
                <div className="col-sm-4" id="videoFrame">
                <iframe width="300" height="200" src="https://www.youtube.com/embed/IhDaSKWEzwA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen loading="lazy"></iframe>
                <p className = "videoCap" id="videoFrame">
                    <a href="https://www.youtube.com/embed/IhDaSKWEzwA">Durham University International</a>
                </p>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Videos; 